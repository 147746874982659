<script setup lang="ts">
import {onMounted,onUnmounted,ref,watch,reactive,computed} from "vue";
import {UiPlayer}                                          from "../../uikit";
import MarkedLine                                          from "./make/MarkedLine.vue";

const model=defineModel ();
const disabledNext=defineModel ('disabledNext');
disabledNext.value=true;
const playing=ref (false);
const position=ref (0);
const bind=ref (false);
const starts=reactive ([]);
const stops=reactive ([]);
const ranges=computed (()=>starts.map ((start,i)=>[start,stops[i]]));
const lines=computed<Array<string>> (()=>model.value['lyrics'].trim ().split ("\n").map (l=>l.trim ()));
const markedLines=computed (markLines);
watch (bind,watchBind);
watch (ranges,watchRanges);
onMounted (mounted);
onUnmounted (unmounted);
const events={play:progress,progress,pause:stop,end:stop};

declare type MarkedLineType={from:number,to:number|undefined,line:string};

function markLines ():Array<MarkedLineType>
	{
		return lines.value.map (makeLine);
		
		function makeLine (line:string,i:number):MarkedLineType
			{
				const ts=ranges.value[i] ?? [];
				const [from,to]=ts;
				return {from,to,line};
			}
	}

function watchBind (bind:boolean):void
	{
		if (bind) starts.push (position.value);
		else stops.push (position.value);
	}

function watchRanges (ranges:Array<Array<number>>):void
	{
		model.value['ttml']=ranges.map ((r,i)=>
		{
			const [begin,end,line]=[...r,lines.value[i]];
			return {begin,end,line};
		});
		if (ranges.length>=lines.value.length)
			{
				const last=ranges.slice (-1)[0];
				const to=last.slice (-1)[0];
				if (to)
					{
						disabledNext.value=false;
						unmounted ();
					}
			}
	}

function progress ({current}):void
	{
		playing.value=true;
		position.value=current;
	}

function stop ():void
	{
		playing.value=false;
	}

function mounted ():void
	{
		window.addEventListener ('keydown',keydown);
		window.addEventListener ('keyup',keyup);
		window.addEventListener ('blur',blur);
	}

function unmounted ():void
	{
		window.removeEventListener ('keydown',keydown);
		window.removeEventListener ('keyup',keyup);
		window.removeEventListener ('blur',blur);
	}

function keydown (event):void
	{
		if (disabledNext.value)
			{
				const {altKey,ctrlKey,shiftKey,keyCode}=event;
				if (playing.value && !(altKey || ctrlKey || shiftKey) && keyCode===32)
					{
						bind.value=true;
						event.stopPropagation ();
						event.preventDefault ();
					}
			}
	}

function keyup (event):void
	{
		if (event.keyCode===32)
			{
				bind.value=false;
				event.stopPropagation ();
				event.preventDefault ();
			}
	}

function blur ():void
	{
		bind.value=false;
	}
</script>
<template>
	<div v-text="model['file'].name"/>
	<ui-player :src="model['file']" v-on="events"/>
	<div id="expander">
		<marked-line v-for="line in markedLines" v-bind="line"/>
	</div>
</template>
<style scoped lang="less">
</style>

<script setup lang="ts">
import {inject,ref,provide,watch,nextTick,onMounted} from "vue";
import {isPlainObject}                               from "lodash";
import {AxiosWrapper}                                from "bomber-net-axios-wrapper-ts";
import {UiStyle}                                     from "../uikit";
import langs                                         from "./langs";
import AppMain                                       from "./AppMain.vue";
import AppLogin                                      from "./AppLogin.vue";

const axios:AxiosWrapper=inject ('axios');
const user=ref (undefined);
const translate=ref ({});
const lang=ref ('ru');
axios.unauthorized (()=>user.value=null);
axios.sessionExpired (sessionExpired);
axios.success (success);
axios.get ('user').run ();
watch (lang,lang=>
{
	translate.value=langs[lang];
	axios.put (`user/${user.value.id}`,{lang}).run ();
});
provide ('user',user);
provide ('translate',translate);
provide ('lang',lang);

function success (data:any,{request}):void
	{
		let {responseURL}=request;
		const path=responseURL.replace (axios.defaults.baseURL,'');
		if (['/user','/login','/logout'].includes (path))
			{
				if (isPlainObject (data))
					{
						user.value=data;
						translate.value=langs[data.lang];
						lang.value=data.lang;
					} else
					{
						user.value=null;
						translate.value={};
						lang.value='ru';
					}
			}
		if (user.value && path===`/user/${user.value.id}`)
			{
				nextTick (()=>user.value=data);
			}
	}

function sessionExpired ():boolean
	{
		const xhr=new XMLHttpRequest ();
		xhr.open ('GET','',false);
		xhr.send ();
		return xhr.status===200;
	}
</script>
<template>
	<ui-style>
		<app-main v-if="user"/>
		<app-login v-else-if="user===null"/>
	</ui-style>
</template>

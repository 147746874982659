<script setup lang="ts">
import {Directive,inject,ComputedRef} from "vue";

defineProps (
	{
		accept:String
	});
const emit=defineEmits (['file']);
const vStyle:Directive=inject ('vStyle');
const style:ComputedRef=inject ('style');

function drop (event):void
	{
		let files;
		switch (event.type)
			{
				case 'change':
					files=event.target.files;
					break;
				case 'drop':
					files=event.dataTransfer.files;
					break;
			}
		emit ('file',files);
	}
</script>
<template>
	<div v-style="style" class="ui-elemnt-group ui-dropfile-group">
		<div v-style="style" class="ui-element-main ui-dropfile-main">
			<div v-style="style" v-if="$slots.default" class="ui-label ui-dopfile-label">
				<slot/>
			</div>
			<div v-style="style" class="ui-dropfile-target">
				<label @drop.stop.prevent="drop" @dragover.stop.prevent @dragenter.stop.prevent @dragleave.stop.prevent>
					<slot name="dropzone"/>
					<input :accept="accept" @change="drop" type="file" style="width:0;height:0;display:none;">
				</label>
				<slot name="preview"/>
			</div>
		</div>
	</div>
</template>
<style src="./UiDropfile.less" scoped lang="less"/>

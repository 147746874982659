<script setup lang="ts">
import {Directive,ComputedRef,inject} from "vue";

const vStyle:Directive=inject ('vStyle');
const style:ComputedRef=inject ('style');
</script>
<template>
	<header v-style="style" v-if="$slots.header">
		<slot name="header"/>
	</header>
	<nav v-style="style" v-if="$slots.nav">
		<slot name="nav"/>
	</nav>
	<main v-style="style" v-if="$slots.default()">
		<slot/>
	</main>
	<footer v-style="style" v-if="$slots.footer">
		<slot name="footer"/>
	</footer>
</template>
<style>
html, body
{
	margin:0;
	padding:0;
	width:100%;
	height:100%;
}

body
{
	&[data-dark]
	{
		color:var(--cl-fg);
		background-color:var(--cl-bg);
	}
}
</style>

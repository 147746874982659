<script setup lang="ts">
import {Ref,inject} from "vue";
import {PhChartBar} from "@phosphor-icons/vue";

const translate:Ref=inject ('translate');
</script>
<template>
	<div v-text="translate.statistics" class="h1"/>
	<div id="stub">
		<ph-chart-bar size="64px"/>
		<div v-text="translate.statisticsWillBeAvailableSoon"/>
	</div>
</template>
<style scoped lang="less">
#stub
{
	width:100%;
	height:400px;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
	box-shadow:var(--shdw-1);
	color:var(--cl-gray-8);
}
</style>

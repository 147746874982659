<script setup lang="ts">
import {onMounted,onUnmounted,reactive} from 'vue';
import {PhMusicNotes} from "@phosphor-icons/vue";
import {mapValues,forIn} from 'lodash';
import {UiForm,UiInput,UiCheckbox,UiLayout,UiButton} from "../uikit";

const formData=reactive ({});
const errors=reactive ({});
onMounted (()=>document.body.id='login');
onUnmounted (()=>document.body.removeAttribute ('id'));
const onErrors=(errorsData:object)=>forIn (mapValues (errorsData,'0'),(v,k)=>errors[k]=v);
</script>
<template>
	<ui-layout>
		<ui-form @errors="onErrors" :data="formData" target="login">
			<ph-music-notes size="48px"/>
			<div>
				<div>
					KADMIR DIGITAL
				</div>
				<div>
					Сервис дистрибуции музыки
				</div>
			</div>
			<ui-input v-model="formData['email']" v-model:error="errors['email']" type="email">
				Email
			</ui-input>
			<ui-input v-model="formData['password']" v-model:error="errors['password']" type="password">
				Пароль
			</ui-input>
			<ui-checkbox v-model="formData['remember']">Запомнить</ui-checkbox>
			<ui-button type="submit">Войти</ui-button>
		</ui-form>
	</ui-layout>
</template>
<style src="./AppLogin.less" lang="less"/>

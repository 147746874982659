<script setup lang="ts">

import {Directive,inject,ComputedRef} from "vue";

defineProps (
	{
		rows:{type:Array<object>,required:true},
		columns:{type:Object,required:true}
	});
const vStyle:Directive=inject ('vStyle');
const style:ComputedRef=inject ('style');

function headClasses (name:string):Array<string>
	{
		const classes=['ui-table-cell','ui-table-head-cell'];
		classes.push (`ui-table-head-cell-${name}`);
		return classes;
	}

function cellClasses (name:string,row:object,column:object):Array<string>
	{
		const classes=['ui-table-cell','ui-table-body-cell'];
		classes.push (`ui-table-body-cell-${name}`);
		const v=column['var'];
		if (v) classes.push (`ui-table-body-cell-${v}-${row[v]}`);
		return classes;
	}

function subCellClasses (name:string):Array<string>
	{
		const classes=['ui-table-body-sub-cell'];
		classes.push (`ui-table-body-sub-cell-${name}`);
		return classes;
	}
</script>
<template>
	<div v-style="style" class="ui-table-group">
		<table v-style="style" class="ui-table">
			<thead v-style="style" class="ui-table-head">
				<tr v-style="style" class="ui-table-row ui-table-head-row">
					<th v-style="style" v-for="(column,name) in columns" v-text="column.title" :class="headClasses (name)"/>
				</tr>
			</thead>
			<tbody v-style="style" class="ui-table-body">
				<tr v-style="style" v-for="row in rows" class="ui-table-row ui-table-body-row">
					<td v-style="style" v-for="(column,name) in columns" :class="cellClasses (name,row,column)">
						<span v-style="style" v-for="name in column.names" v-text="row[name]" :class="subCellClasses (name)"/>
					</td>
				</tr>
			</tbody>
			<tfoot>
			</tfoot>
		</table>
	</div>
</template>
<style src="./UiTable.less" scoped lang="less"/>

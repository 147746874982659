<script setup lang="ts">
import {inject,ref,computed,Ref} from "vue";
import {AxiosWrapper}            from "bomber-net-axios-wrapper-ts";
import {UiTable}             from "../uikit";

const axios:AxiosWrapper=inject ('axios');
const users=ref ([]);
const titles=ref ({});
axios.get ('user/index').success (success).run ();
const table=computed (()=>({rows:users.value,columns:titles.value}));
const translate:Ref=inject ('translate');

function success ({rows,columns}):void
	{
		users.value=rows;
		titles.value=columns;
	}
</script>
<template>
	<div v-text="translate.manageUsers"/>
	<ui-table v-bind="table"/>
</template>
<style src="./Users.less" lang="less"/>

<script setup lang="ts">
import {ref,inject}   from "vue";
import {AxiosWrapper} from "bomber-net-axios-wrapper-ts";
import ReleaseBox     from "./releases/ReleaseBox.vue";

const axios:AxiosWrapper=inject ('axios');
const releases=ref ([]);
axios.get ('release').success (data=>releases.value=data).run ();
</script>
<template>
	<release-box v-for="release in releases" :release="release"/>
</template>
<style src="./Releases.less" lang="less"/>

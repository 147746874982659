export default (
	{
		profileSettings:'Настройки профиля',
		logout:'Выйти',
		welcome:'Добро пожаловать',
		settings:'Настройки',
		uploadNewRelease:'Загрузить новый релиз',
		back:'Назад',
		sendRelease:'Отправить релиз',
		next:'Далее',
		manageUsers:'Управление пользователями',
		themeSettings:'Настройки темы',
		chooseBetweenLightAndDarkTheme:'Выберите между светлой и темной темой',
		switchToLightTheme:'Переключить на светлую тему',
		switchToDarkTheme:'Переключить на темную тему',
		releaseTitle:'Название релиза',
		type:'Тип',
		versionOptional:'Версия (опционально)',
		artistName:'Имя артиста',
		releaseDate:'Дата релиза',
		preorderOptional:'Дата предзаказа (опционально)',
		cover:'Обложка',
		clickOrDragTheImage:'Нажмите или перетащите изображение',
		PNG_JPGupTo10MB:'PNG, JPG до 10МБ',
		pleaseCheckAllDetailsCarefullyBeforeSending:'Пожалуйста, внимательно проверьте все данные перед отправкой',
		afterTheReleaseIsSubmittedForModerationDataChangesWillRequireReApproval:'После отправки релиза на модерацию изменение данных потребует повторного одобрения',
		baseInformation:'Основная информация',
		title:'Название',
		artist:'Исполнитель',
		releaseType:'Тип релиза',
		version:'Версия',
		preorderDate:'Дата предзаказа',
		tracks:'Треки',
		textAuthors:'Авторы текста',
		musicAuthors:'Авторы музыки',
		distribution:'Дистрибуция',
		selectedPlatforms:'Выбранные площадки',
		iTunesPricingPolicy:'Ценовая политика iTunes',
		platformSelection:'Выбор площадок',
		priceCategory:'Категория цены',
		addMoreTrack:'Добавить еще трек',
		trackTitle:'Название трека',
		audiofile:'Аудиофайл',
		clickToReplaceFile:'Нажмите, чтобы заменить файл',
		clickOrDragTheAudioFile:'Нажмите или перетащите аудиофайл',
		MP3_WAVupTo50MB:'MP3, WAV до 50МБ',
		lyrics:'Текст',
		timedTextTtmlOptional:'Синхронизированный текст (TTML) (опционально)',
		selectTTMLfile:'Выберите файл TTML',
		profanity:'Ненормативная лексика',
		videoShotLinkOptional:'Видеошот ссылка (опционально)',
		musicClipLinkOptional:'Музыкальный клип ссылка (опционально)',
		check:'Проверка',
		track:'Трек',
		uploadRelease:'Загрузить релиз',
		statistics:'Статистика',
		myReleases:'Мои релизы',
		karaokeLyrics:'Караоке текст',
		users:'Пользователи',
		allReleases:'Все релизы',
		underReview:'На проверке',
		allPlatforms:'Все площадки',
		selectCategory:'Выберите категорию...',
		single:'Сингл',
		EP:'EP',
		album:'Альбом',
		maxiSingle:'Макси-сингл',
		languageSettings:'Настройки языка',
		chooseYourPreferredLanguage:'Выберите предпочитаемый язык',
		emailSettings:'Настройки электронной почты',
		newEmailAddress:'Новый адрес электронной почты',
		update:'Обновить',
		passwordSettings:'Настройки пароля',
		currentPassword:'Текущий пароль',
		newPassword:'Новый пароль',
		confirmPassword:'Подтвердите пароль',
		notifications:'Уведомления',
		markAllAsRead:'Отметить все как прочитанные',
		statisticsWillBeAvailableSoon:'Статистика будет доступна в ближайшее время',
		karaokeEditor:'Караоке редактор',
		subtitle:'Подзаголово',
		downloadTTML:'Скачать TTML',
	});

<script setup lang="ts">
import {Ref,inject}                                                 from "vue";
import {PhUploadSimple,PhTrash,PhX}                                 from "@phosphor-icons/vue";
import {UiInput,UiDropfile,UiButton,UiPlayer,UiTextarea,UiCheckbox} from "../../../uikit";

const model=defineModel ();
if (!model.value) model.value={};

const translate:Ref=inject ('translate');

function file (files):void
	{
		const file:File=files[0];
		if (file.size>=52428800) return;
		if (!['audio/mpeg','audio/wav'].includes (file.type)) return;
		model.value['file']=file;
	}

function ttml ({target}):void
	{
		const {files}=target;
		const file:File=files[0];
		if (file.type!=='application/ttml+xml') return;
		model.value['ttml']=file;
	}
</script>
<template>
	<template v-if="model">
		<div>
			<ui-input v-model="model['title']">
				{{translate.trackTitle}}
			</ui-input>
			<ui-input v-model="model['version']">
				{{translate.versionOptional}}
			</ui-input>
		</div>
		<div>
			<ui-dropfile @file="file" accept="audio/mpeg,audio/x-wav">
				{{translate.audiofile}}
				<template v-if="model['file']" #preview>
					<div class="track-file">
						<ui-player :src="model['file']"/>
						<div v-text="model['file'].name"/>
						<div v-text="translate.clickToReplaceFile"/>
					</div>
					<ui-button @click="model['file']=undefined">
						<ph-trash size="20px"/>
					</ui-button>
				</template>
				<template v-else #dropzone>
					<ph-upload-simple size="48px"/>
					<div v-text="translate.clickOrDragTheAudioFile"/>
					<div v-text="translate.MP3_WAVupTo50MB"/>
				</template>
			</ui-dropfile>
		</div>
		<div>
			<ui-input v-model="model['text_authors']">
				{{translate.textAuthors}}
			</ui-input>
			<ui-input v-model="model['music_authors']">
				{{translate.musicAuthors}}
			</ui-input>
		</div>
		<div>
			<ui-textarea v-model="model['lyrics']">
				{{translate.lyrics}}
			</ui-textarea>
		</div>
		<div>
			<div v-text="translate.timedTextTtmlOptional"/>
			<div style="display:flex;flex-direction:row;justify-content:flex-end;">
				<label style="outline:var(--outline);padding:var(--sz-06);border-radius:var(--sz-06);cursor:pointer;">
					<div v-if="model['ttml']" v-text="model['ttml'].name"/>
					<div v-else v-text="translate.selectTTMLfile"/>
					<input @change="ttml" type="file" accept="application/ttml+xml" style="display:none;">
				</label>
				<ui-button v-if="model['ttml']" @click="model['ttml']=undefined" style="background-color:var(--cl-transparent);color:var(--cl-red-D);">
					<ph-x size="20px"/>
				</ui-button>
			</div>
		</div>
		<div>
			<ui-checkbox v-model="model['profanity']">
				{{translate.profanity}}
			</ui-checkbox>
		</div>
		<div>
			<ui-input v-model="model['videoshot']">
				{{translate.videoShotLinkOptional}}
			</ui-input>
			<ui-input v-model="model['clip']">
				{{translate.musicClipLinkOptional}}
			</ui-input>
		</div>
	</template>
</template>
<style src="./TrackForm.less" scoped lang="less"/>

<script setup lang="ts">
import {AxiosWrapper}                                                              from "bomber-net-axios-wrapper-ts";
import {inject,onMounted,onUnmounted,ref,watch,Directive,ComputedRef,Ref,computed} from "vue";
import {PhBell}                                                                    from "@phosphor-icons/vue";
import NotificationItem                                                   from "./notifications/NotificationItem.vue";

const axios:AxiosWrapper=inject ('axios');
const notifications=ref ([]);
const notificationsView=ref (false);
const notificationsPosition=ref ({top:undefined,right:undefined});
watch (notifications,notifications=>
{
	if (!notifications.length) notificationsView.value=false;
});
watch (notificationsView,view=>
{
	if (view)
		{
			const bell:HTMLElement=document.getElementById ('notifications-bell');
			const {bottom,right}=bell.getBoundingClientRect ();
			notificationsPosition.value={top:`${bottom}px`,right:`${document.documentElement.clientWidth-right}px`};
			setTimeout (()=>window.addEventListener ('click',clickOutside),100);
			
			function clickOutside ()
				{
					notificationsView.value=false;
					window.removeEventListener ('click',clickOutside);
				}
		}
});

let intervalId=undefined;
onMounted (()=>
{
	intervalId=setInterval (fetchNotifications,10000);
	fetchNotifications ();
});
onUnmounted (()=>clearInterval (intervalId));
const vStyle:Directive=inject ('vStyle');
const style:ComputedRef=inject ('style');
const translate:Ref=inject ('translate');

async function fetchNotifications ()
	{
		axios.get ('notification').success (success).run ();
	}

function toggleNotificationsList ()
	{
		if (notifications.value.length) notificationsView.value= !notificationsView.value;
	}

function select (uuid?:any):void
	{
		const regexp=/^[\da-f]{8}-[\da-f]{4}-[0-57][\da-f]{3}-[\da-f]{4}-[\da-f]{12}$/i;
		const url=regexp.test (uuid)?`notification/${uuid}`:'notification';
		axios.delete (url).success (success).run ();
	}

function success (data:Array<any>):void
	{
		notifications.value=data;
	}
</script>
<template>
	<div @click="toggleNotificationsList" id="notifications-bell" :style="{cursor:notifications.length?'pointer':undefined}">
		<ph-bell size="20px"/>
		<div v-if="notifications.length" v-text="notifications.length" id="notifications-balloon" class="blue-label"/>
	</div>
	<teleport v-if="notificationsView" to="body">
		<div v-style="style" id="notifications-popup" :style="notificationsPosition">
			<div id="notificatins-caption">
				<span v-text="translate.notifications"/>
				<span v-text="translate.markAllAsRead" @click="select"/>
			</div>
			<div id="notifications-list">
				<notification-item v-for="notification in notifications" :notification="notification" @select="select"/>
			</div>
		</div>
	</teleport>
</template>
<style src="./HeaderNotifications.less" lang="less"/>

<script setup lang='ts'>
import {computed,onMounted,provide,watch,ref,DirectiveBinding,VNode,inject,Ref,ComputedRef} from 'vue';
import {AxiosWrapper}                                                                       from "bomber-net-axios-wrapper-ts";

const axios:AxiosWrapper=inject ('axios');
const dark=ref (false);
const user:Ref=inject ('user');
const style:ComputedRef=computed (()=>({dark:dark.value}));
watch (user,user=>dark.value=user?.dark_theme,{immediate:true});
watch (style,style=>bodyDarkTheme (style['dark']));
watch (dark,dark_theme=>axios.put (`user/${user.value.id}`,{dark_theme}).run ());
onMounted (mounted);
provide ('dark',dark);
provide ('style',style);
provide ('vStyle',vStyle);

function mounted ():void
	{
		bodyDarkTheme (style.value.dark);
	}

function vStyle (el:HTMLElement,binding:DirectiveBinding,vnode:VNode):void
	{
		elDarkTheme (el,binding.value.dark)
	}

function bodyDarkTheme (dark:boolean):void
	{
		elDarkTheme (document.body,dark);
	}

function elDarkTheme (el:HTMLElement,dark:boolean):void
	{
		const dataset=el.dataset;
		if (dark) dataset.dark='';
		else delete dataset.dark;
	}
</script>
<template>
	<slot/>
</template>
<style src="./UiStyle.less" lang="less"/>

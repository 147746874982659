<script setup lang="ts">
import {computed}          from "vue";
import {isUndefined,floor} from "lodash";

const props=defineProps (
	{
		from:{type:Number,required:true},
		to:{type:Number,required:true},
		line:{type:String,required:true},
	});
const strFrom=computed (()=>ms (props.from));
const strTo=computed (()=>ms (props.to));

function ms (ts:number|undefined):string
	{
		if (isUndefined (ts)) return '--:--,--';
		let m=floor (ts/60).toFixed (0);
		if (m.length<2) m=`0${m}`;
		let s=(ts%60).toFixed (2);
		if (s.length<5) s=`0${s}`;
		return `${m}:${s}`;
	}
</script>
<template>
	<div class="marked-line-group">
		<div v-text="strFrom" class="marked-line-from"/>
		<div v-text="strTo" class="marked-line-to"/>
		<div v-text="line" class="marked-line-line"/>
	</div>
</template>
<style scoped lang="less">
.marked-line-group
{
	padding:var(--sz-10);
	display:flex;
	flex-direction:row;
	justify-content:flex-start;
	align-items:center;
	
	& > *
	{
		display:flex;
		flex-direction:row;
		align-items:center;
		
		&:not(:last-child)
		{
			justify-content:center;
			margin-right:var(--sz-10);
			width:var(--sz-30);
		}
		
		&:last-child
		{
			margin-left:var(--sz-10);
			justify-content:flex-start;
		}
	}
}
</style>

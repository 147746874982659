<script setup lang="ts">
import {Directive,inject,ComputedRef,Ref} from "vue";
import SettingsLanguage                   from "./settings/SettingsLanguage.vue";
import SettingsTheme                      from "./settings/SettingsTheme.vue";
import SettingsEmail                      from "./settings/SettingsEmail.vue";
import SettingsPassword                   from "./settings/SettingsPassword.vue";

const vStyle:Directive=inject ('vStyle');
const style:ComputedRef=inject ('style');
const translate:Ref=inject ('translate');
</script>
<template>
	<div v-text="translate.settings" class="h1"/>
	<div v-style="style" class="card">
		<settings-language/>
	</div>
	<div v-style="style" class="card">
		<settings-theme/>
	</div>
	<div v-style="style" class="card">
		<settings-email/>
	</div>
	<div v-style="style" class="card">
		<settings-password/>
	</div>
</template>
<style lang="less">
.card
{
	padding:var(--sz-20);
	outline:var(--outline);
	outline-color:var(--cl-gray-2);
	border-radius:var(--sz-05);
	
	&[data-dark]
	{
		outline-color:var(--cl-gray-D);
	}
	
	.ui-button-group
	{
		margin-top:var(--sz-05);
	}
}
</style>

<script setup lang="ts">
import {ComputedRef,inject,Directive,computed} from "vue";

const props=defineProps (
	{
		name:{type:String,required:true},
		options:{type:Object,required:true},
		disabled:Boolean,
		readonly:Boolean,
	});
const model=defineModel ();
const error=defineModel ('error');
const vStyle:Directive=inject ('vStyle');
const style:ComputedRef=inject ('style');
const bind=computed (()=>
	{
		const {disabled,readonly}=props;
		return {disabled,readonly};
	});
</script>
<template>
	<div v-style="style" class="ui-element-group ui-radio-group">
		<label v-for="(option,value) in options" class="ui-element-item ui-radio-item">
			<input v-model="model" :value="value" v-bind="bind" type="radio" @focus="$emit ('update:error',undefined)" class="ui-radio">
			<span v-text="option"/>
		</label>
		<div v-if="error" v-text="error" class="ui-error ui-radio-error"/>
	</div>
</template>
<style src="./UiRadio.less" scoped lang="less"/>

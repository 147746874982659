<script setup lang="ts">
import {useRouter}                 from "vue-router";
import {kebabCase,forIn,mapValues} from "lodash";
import {onMounted,inject,Ref}      from "vue";
import {UiLayout}                  from "../uikit";
import MainHeader                  from "./main/MainHeader.vue";
import MainNav                     from "./main/MainNav.vue";
import MainContent                 from "./main/MainContent.vue";
import {admin,artist}              from "./AppMain/routes";

const router=useRouter ();
const user:Ref=inject ('user');
const translate:Ref=inject ('translate');
const routes=user.value.role==='ADMIN'?admin:artist;
router.clearRoutes ();
forIn (routes,(route,name)=>router.addRoute (Object.assign (route,{name})));
router.afterEach ((to)=>handleRoute (to));
onMounted (()=>
{
	let path=window.location.pathname;
	if (!router.getRoutes ().map (route=>route.path).includes (path)) path='/';
	router.push (path);
});

function handleRoute (route:object)
	{
		if (route['meta'].user && route['meta'].user!==user.value.role) return false
		document.body.id=kebabCase (route['name']);
	}
</script>
<template>
	<ui-layout ref="layout">
		<template #header>
			<main-header/>
		</template>
		<template #nav>
			<main-nav/>
		</template>
		<main-content/>
	</ui-layout>
</template>
<style src="./AppMain.less" lang="less"/>

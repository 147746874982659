<script setup lang="ts">
import {inject,Ref}   from "vue";
import {PhMusicNotes} from "@phosphor-icons/vue";

const user:Ref=inject ('user');
const translate:Ref=inject ('translate');
</script>
<template>
	<div id="nav-welcome">
		<div>
			<ph-music-notes size="32px"/>
			<span>
				KADMIR DIGITAL
			</span>
		</div>
		<div>
			<span v-text="`${translate.welcome}, ${user.name}`"/>
		</div>
	</div>
</template>
<style scoped lang="less">
#nav-welcome
{
	*
	{
		display:flex;
		align-items:center;
		justify-content:center;
	}
	
	& > *
	{
		&:first-child
		{
			svg
			{
				color:var(--cl-4444dd);
			}
			
			span
			{
				margin-left:var(--sz-05);
				font-size:var(--fs-4);
				font-weight:var(--fw-8);
			}
		}
		
		&:last-child
		{
			font-size:var(--fs-2);
			color:var(--cl-gray-B);
			margin:var(--sz-08) 0;
		}
	}
}
</style>

<script setup lang="ts">
import {Ref,computed,Directive,inject,ComputedRef,watch} from "vue";
import {PhTriangle,PhExclamationMark}                    from "@phosphor-icons/vue";
import {mapValues}                                       from "lodash";
import types                                             from "./types";
import platforms                                         from "./platforms";
import {UiImage}                                         from "../../uikit";

const model:Ref=defineModel ();
const disabledNext:Ref=defineModel ('disabledNext');
const translate:Ref=inject ('translate');
disabledNext.value=false;
if (!model.value['platformSelect']) model.value['platformSelect']='select';
const translatedTypes=computed (()=>mapValues (types,v=>translate.value[v]));
const priceType=computed (()=>
{
	const pt:string=model.value['price_type'];
	return `$${(parseInt (pt.replace ('P',''))/100).toFixed (2)}`;
});
const vStyle:Directive=inject ('vStyle');
const style:ComputedRef=inject ('style');
</script>
<template>
	<div class="card-exclamation">
		<div style="display:flex;flex-direction:row;justify-content:flex-start;align-items:flex-start;">
			<div style="position:relative;color:var(--cl-f59e0b);">
				<ph-triangle size="21px" style="position:relative;"/>
				<ph-exclamation-mark size="15px" style="position:absolute;left:3px;top:3px;"/>
			</div>
			<div style="margin-left:var(--sz-05);color:var(--cl-92400e);">
				<div v-text="translate.pleaseCheckAllDetailsCarefullyBeforeSending" style="font-weight:var(--fw-7);"/>
				<div v-text="translate.afterTheReleaseIsSubmittedForModerationDataChangesWillRequireReApproval"/>
			</div>
		</div>
	</div>
	<div v-text="translate.baseInformation" style="font-size:var(--fs-3);font-weight:var(--fw-7);"/>
	<div style="margin-top:var(--sz-05);">
		<div>
			<div v-text="translate.title"/>
			<div v-text="model['title']"/>
		</div>
		<div>
			<div v-text="translate.artist"/>
			<div v-text="model['artist']"/>
		</div>
	</div>
	<div>
		<div>
			<div v-text="translate.releaseType"/>
			<div v-text="translatedTypes[model['type']]"/>
		</div>
		<div>
			<div v-text="translate.version"/>
			<div v-text="model['version']?model['version']:'—'"/>
		</div>
	</div>
	<div>
		<div>
			<div v-text="translate.releaseDate"/>
			<div v-text="model['release']"/>
		</div>
		<div>
			<div v-text="translate.preorderDate"/>
			<div v-text="model['preorder']?model['preorder']:'—'"/>
		</div>
	</div>
	<div v-text="translate.cover"/>
	<div style="margin-top:0;">
		<div style="width:250px;max-height:250px;">
			<ui-image :src="model['cover']" style="max-width:100%;max-height:100%;"/>
		</div>
	</div>
	<div v-text="translate.tracks" style="font-size:var(--fs-3);font-weight:var(--fw-7);"/>
	<template v-for="(track,index) in model['tracks']">
		<div v-text="`${translate.track} ${index+1}: ${track.title}`" style="margin-top:var(--sz-08);font-size:var(--fs-2);font-weight:var(--fw-7);"/>
		<div style="margin-top:0;">
			<div v-text="`${translate.textAuthors}: ${track.text_authors}`"/>
			<div v-text="`${translate.musicAuthors}: ${track.music_authors}`"/>
		</div>
	</template>
	<div v-text="translate.distribution" style="font-size:var(--fs-3);font-weight:var(--fw-7);"/>
	<div v-text="translate.selectedPlatforms" style="margin-top:var(--sz-05);"/>
	<div style="justify-content:flex-start;margin-top:var(--sz-03);flex-wrap:wrap;">
		<template v-if="model['platformSelect']==='select'">
			<div v-style="style" v-for="platform in model['platforms']" v-text="platforms[platform]" class="blue-label" style="width:min-content;margin-top:var(--sz-05)"/>
		</template>
		<template v-else>
			<div v-style="style" v-for="platform in Object.keys (platforms)" v-text="platforms[platform]" class="blue-label" style="width:min-content;margin-top:var(--sz-05)"/>
		</template>
	</div>
	<div>
		<div v-text="translate.iTunesPricingPolicy"/>
	</div>
	<div>
		<div v-text="priceType"/>
	</div>
</template>
<style scoped lang="less">
</style>

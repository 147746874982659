<script setup lang="ts">
import {Ref,inject,watch,ModelRef} from "vue";
import {PhTrash,PhUploadSimple}    from "@phosphor-icons/vue";
import {UiDropfile,UiButton,UiPlayer,UiInput,UiTextarea} from "../../uikit";

const model:ModelRef<any>=defineModel ();
const disabledNext=defineModel ('disabledNext');
watch (model.value,value=>
{
	for (let field of ['file','artist','title','subtitle','lyrics'])
		{
			if (!value[field])
				{
					disabledNext.value=true;
					return;
				}
		}
	disabledNext.value=false;
},{immediate:true});
const translate:Ref=inject ('translate');

function file (files):void
	{
		const file:File=files[0];
		if (file.size>=52428800) return;
		if (!['audio/mpeg','audio/wav'].includes (file.type)) return;
		model.value['file']=file;
	}
</script>
<template>
	<div>
		<ui-dropfile @file="file" accept="audio/mpeg,audio/x-wav">
			{{translate.audiofile}}
			<template v-if="model['file']" #preview>
				<div class="track-file">
					<ui-player :src="model['file']"/>
					<div v-text="model['file'].name"/>
					<div v-text="translate.clickToReplaceFile"/>
				</div>
				<ui-button @click="model['file']=undefined">
					<ph-trash size="20px"/>
				</ui-button>
			</template>
			<template v-else #dropzone>
				<ph-upload-simple size="48px"/>
				<div v-text="translate.clickOrDragTheAudioFile"/>
				<div v-text="translate.MP3_WAVupTo50MB"/>
			</template>
		</ui-dropfile>
	</div>
	<div>
		<ui-input v-model="model['artist']">
			{{translate.artist}}
		</ui-input>
		<ui-input v-model="model['title']">
			{{translate.title}}
		</ui-input>
	</div>
	<div>
		<ui-input v-model="model['subtitle']">
			{{translate.subtitle}}
		</ui-input>
	</div>
	<div id="expander">
		<ui-textarea v-model="model['lyrics']" id="lyrics">
			{{translate.lyrics}}
		</ui-textarea>
	</div>
</template>
<style src="./Prepare.less" scoped lang="less"/>

<script setup lang="ts">
import {Ref,computed,inject}  from "vue";
import {useRouter,RouterLink} from "vue-router";
import {UiNavItem}            from "../../uikit";
import NavWelcome             from "./nav/NavWelcome.vue";

const router=useRouter ();
const translate:Ref=inject ('translate');
const routes=computed (()=>
{
	const user:Ref=inject ('user');
	return router.getRoutes ().filter (route=>!route.meta.user || route.meta.user===user.value.role).map (mepRoute);
	
	function mepRoute ({path,meta})
		{
			const {title,icon}=meta;
			return {path,title:translate.value[title],icon};
		}
});
</script>
<template>
	<nav-welcome/>
	<ui-nav-item v-for="route in routes" v-bind="route" :component="RouterLink" path-bind="to"/>
</template>

<script setup lang="ts">
import {computed,Directive,inject,ComputedRef} from "vue";

const props=defineProps (
	{
		path:{type:String,required:true},
		title:{type:String,required:true},
		icon:{type:Object,required:false},
		component:{type:Object,required:true},
		pathBind:{type:String,default:'path'}
	});
const bind=computed (()=>
{
	const key=props.pathBind;
	const bind={};
	bind[key]=props.path;
	return bind;
});
const vStyle:Directive=inject ('vStyle');
const style:ComputedRef=inject ('style');
</script>
<template>
	<component v-style="style" :is="component" v-bind="bind" class="ui-nav-item-group">
		<component :is="icon" size="20px" class="ui-nav-item-icon"/>
		<span v-text="title" class="ui-nav-item-title"/>
	</component>
</template>
<style src="./UiNavItem.less" scoped lang="less"/>

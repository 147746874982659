<script setup lang="ts">
import {inject,ComputedRef,ModelRef,Directive,computed} from "vue";

const props=defineProps (
	{
		disabled:Boolean,
		readonly:Boolean,
		cols:Number,
		rows:Number,
	});
const model:ModelRef<string>=defineModel ();
const error:ModelRef<string>=defineModel ('error');
const bind=computed (()=>
	{
		const {disabled,readonly,cols,rows}=props;
		return {disabled,readonly,cols,rows};
	});
const style:ComputedRef=inject ('style');
const vStyle:Directive=inject ('vStyle');
</script>
<template>
	<label v-style="style" class="ui-element-group ui-textarea-group">
		<div class="ui-element-main ui-textarea-main">
			<span v-if="$slots.default" class="ui-label ui-textarea-label">
				<slot/>
			</span>
			<textarea v-model="model" v-bind="bind" @focus="$emit ('update:error',undefined)" class="ui-textarea"/>
		</div>
		<div v-if="error" v-text="error" class="ui-error ui-textarea-error"/>
	</label>
</template>
<style src="./UiTextarea.less" scoped lang="less"/>

<script setup lang="ts">
import {ref,watch} from "vue";

const props=defineProps (
	{
		src:{required:true},
		progressInterval:{type:Number,default:.25},
	});
const audioSrc=ref (undefined);
watch (()=>props.src,watchSrc,{immediate:true});
const emit=defineEmits (['progress','play','pause','end']);
const events={play:event,pause:event,ended:event};
let progresIntervalID=undefined;

async function watchSrc (src)
	{
		switch (true)
			{
				case src instanceof File:
					if (src.type.split ('/')[0]!=='audio') return;
					const reader=new FileReader ();
					reader.readAsDataURL (src);
					reader.onload=()=>audioSrc.value=reader.result;
					break;
			}
	}

function event (event):void
	{
		const {target,type}=event;
		if (type==='play') progresIntervalID=setInterval (progress.bind (target),props.progressInterval)
		else if (progresIntervalID)
			{
				clearInterval (progresIntervalID);
				progresIntervalID=undefined;
			}
		const Type={play:'play',pause:'pause',ended:'end'}[type];
		emitEvent (Type,target);
	}

function progress ():void
	{
		emitEvent ('progress',this);
	}

function emitEvent (type:'progress'|'play'|'pause'|'end',source:HTMLAudioElement):void
	{
		const {currentTime,duration}=source;
		const current=currentTime;
		const progress=current/duration;
		if (!(type==='pause' && progress>.999)) emit (type,Object.assign ({type,current,duration,progress}));
	}
</script>
<template>
	<audio :src="audioSrc" v-on="events" controls class="ui-player"/>
</template>
<style scoped lang="less">
</style>

<script setup lang="ts">
import {Ref,inject} from "vue";
import {UiSelect}   from "../../uikit";

const translate:Ref=inject ('translate');
const lang:Ref=inject ('lang');
const options={en:'English',ru:'Русский'};
</script>
<template>
	<div v-text="translate.languageSettings"/>
	<ui-select v-model="lang" :options="options">
		{{translate.chooseYourPreferredLanguage}}
	</ui-select>
</template>
<style scoped lang="less">
</style>

<script setup lang="ts">
import {Ref,inject} from "vue";
import {PhMoon,PhSun} from "@phosphor-icons/vue";
import {UiButton} from "../../../uikit";

const dark:Ref=inject ('dark');
</script>
<template>
	<ui-button @click="dark=!dark">
		<component :is="dark?PhSun:PhMoon" size="20px"/>
	</ui-button>
</template>
<style scoped lang="less">
.ui-button-group
{
	background-color:var(--cl-transparent);
	color:var(--cl-fg);
}
</style>

<script setup lang="ts">
import {Ref,inject,ref,watch} from "vue";
import {AxiosWrapper}         from "bomber-net-axios-wrapper-ts";
import {UiButton}             from "../uikit";
import prepare                from "./karaoke/Prepare.vue";
import make                   from './karaoke/Make.vue';

const axios:AxiosWrapper=inject ('axios');
const stage=ref (0);
const stages=[prepare,make];
const karaoke=ref ({});
const disabledNext=ref (true);
const translate:Ref=inject ('translate');
watch (stage,stg=>
{
	if (stg>=2)
		{
			axios.post ('ttml',karaoke.value,{responseType:'text'}).success (xml=>
			{
				const blob=new Blob ([xml],{type:'application/xml'});
				const a=document.createElement ('a');
				a.href=URL.createObjectURL (blob);
				a.download=`${karaoke.value['title']}.ttml`;
				a.click ();
				karaoke.value={};
				stage.value=0;
			}).run ();
		}
});
</script>
<template>
	<div v-text="translate.karaokeEditor" class="h1"/>
	<component :is="stages[stage]" v-model="karaoke" v-model:disabledNext="disabledNext"/>
	<div>
		<div>
			<ui-button v-if="stage" @click="stage--" id="button-back" v-text="translate.back"/>
		</div>
		<div>
			<ui-button :disabled="disabledNext" v-text="stage?translate.downloadTTML:translate.next" @click="stage++"/>
		</div>
	</div>
</template>
<style src="./Karaoke.less" lang="less"/>

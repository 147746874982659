export default (
	{
		APPLEMUSIC:'Apple Music',
		VKMUSIC:'VK Music',
		SPOTIFY:'Spotify',
		YOUTUBE:'YouTube',
		YOUTUBEMUSIC:'YouTube Music',
		YANDEXMUSIC:'Яндекс.Музыка',
		VKVIDEO:'VK Видеоклипы',
		ZVUK:'Звук',
		TIKTOK:'TikTok',
		LIKEE:'Likee',
		TIDAL:'Tidal',
		AMAZONMUSIC:'Amazon Music',
		SOUNDCLOUD:'Soundcloud',
		INSTAGRAMFACEBOOK:'Instagram/Facebook',
	});

import releases from "./artist/releases";
import uploadRelease from "./artist/uploadReleases";
import statistics from "./artist/statistics";
import karaoke from "./artist/karaoke";
import settings from "./admin/settings";

export default (
	{
		releases,
		uploadRelease,
		statistics,
		karaoke,
		settings,
	});

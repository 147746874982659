<script setup lang="ts">
import {inject,ComputedRef,ModelRef,Directive,computed} from "vue";

const props=defineProps (
	{
		type:String,
		disabled:Boolean,
		readonly:Boolean,
	});
const model:ModelRef<string>=defineModel ();
const error:ModelRef<string>=defineModel ('error');
const inputType=computed (()=>
	{
		const type=props['type']?.toLowerCase ();
		switch (type)
			{
				case 'date':
				case 'datetime-local':
				case 'email':
				case 'month':
				case 'password':
				case 'search':
				case 'tel':
				case 'text':
				case 'time':
				case 'url':
				case 'week':
					return type;
				default:
					return 'text';
			}
	});
const bind=computed (()=>
	{
		const {disabled,readonly}=props;
		return {disabled,readonly};
	});
const style:ComputedRef=inject ('style');
const vStyle:Directive=inject ('vStyle');
</script>
<template>
	<label v-style="style" class="ui-element-group ui-input-group">
		<div class="ui-element-main ui-input-main">
			<span v-if="$slots.default" class="ui-label ui-input-label">
				<slot/>
			</span>
			<input v-model="model" v-bind="bind" :type="inputType" @focus="$emit ('update:error',undefined)" class="ui-input">
		</div>
		<div v-if="error" v-text="error" class="ui-error ui-input-error"/>
	</label>
</template>
<style src="./UiInput.less" scoped lang="less"/>

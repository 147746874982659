<script setup lang="ts">
import {ComputedRef,inject,Directive} from "vue";
import {AxiosWrapper} from "bomber-net-axios-wrapper-ts";
import {AxiosResponse} from "axios";

const props=defineProps (
	{
		data:Object,
		target:String,
		method:{type:String,default:'post'}
	});
const emit=defineEmits (['success','fail','errors','reset']);
const axios:AxiosWrapper=inject ('axios');
const style:ComputedRef=inject ('style');
const vStyle:Directive=inject ('vStyle');

function submit ():void
	{
		const {data,method}=props;
		let {target}=props;
		if (['get','delete'].includes (method) && Object.keys (data))
			{
				target+=target.includes ('?')?'&':'?';
				target+=`${new URLSearchParams (data).toString ()}`;
			}
		const request=axios.request (method,target,data);
		request.fail ((data:any,response:AxiosResponse)=>emit ('fail',data,response));
		request.unprocessableEntity (({errors})=>emit ('errors',errors));
		request.success ((data:any,response:AxiosResponse)=>emit ('success',data,response));
		request.run ();
	}
</script>
<template>
	<form v-style="style" @submit.stop.prevent="submit" @reset.stop="emit ('reset')" class="ui-form">
		<slot/>
	</form>
</template>
<style scoped lang="less">
.ui-form
{
	&[data-dark]
	{
		color:var(--cl-fg);
		background-color:var(--cl-bg);
	}
}
</style>

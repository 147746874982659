<script setup lang="ts">
import {inject,Ref,watch,useTemplateRef,reactive,computed,Directive,ComputedRef} from "vue";
import {AxiosWrapper}                                                            from "bomber-net-axios-wrapper-ts";
import {useRouter}                                                               from "vue-router";

const router=useRouter ();
const axios:AxiosWrapper=inject ('axios');
const user:Ref=inject ('user');
const menu=reactive ({view:false,right:0,top:0});
const card=useTemplateRef ('card');
const menuStyle=computed (()=>
{
	let {right,top}=menu;
	return {right:`${right}px`,top:`${top}px`};
});
watch (()=>menu.view,async view=>
{
	if (view)
		{
			const cardEl:HTMLElement=card.value;
			const rect=cardEl.getBoundingClientRect ();
			menu.top=rect.bottom;
			menu.right=document.documentElement.clientWidth-rect.right;
			setTimeout (()=>window.addEventListener ('click',clickOutside),100);
			
			function clickOutside ()
				{
					menu.view=false;
					window.removeEventListener ('click',clickOutside);
				}
		}
});

function profile ()
	{
		router.push ('/settings');
	}

function logout ()
	{
		axios.post ('logout').run ();
	}

const vStyle:Directive=inject ('vStyle');
const style:ComputedRef=inject ('style');
const translate:Ref=inject ('translate');
</script>
<template>
	<div @click="menu.view=!menu.view" id="header-user-card" ref="card">
		<div id="header-user-name" v-text="user.name"/>
		<div id="header-user-role" v-text="user.role_name"/>
	</div>
	<teleport v-if="menu.view" to="body">
		<div v-style="style" id="header-user-menu" :style="menuStyle">
			<div @click="profile" id="header-user-menu-profile" v-text="translate.profileSettings"/>
			<div @click="logout" id="header-user-menu-logout" v-text="translate.logout"/>
		</div>
	</teleport>
</template>
<style src="./HeaderUser.less" scoped lang="less"/>

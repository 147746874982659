<script setup lang="ts">
import {watch,computed,Directive,inject,ComputedRef,Ref} from "vue";
import {PhTrash,PhPlus}                                  from "@phosphor-icons/vue";
import {UiButton}                                    from "../../uikit";
import TrackForm                                     from "./tracks/TrackForm.vue";

const model:Ref=defineModel ();
const disabledNext=defineModel ('disabledNext');
if (!model.value.tracks) model.value.tracks=[];
const tracks=computed (()=>model.value.tracks ?? []);
const filteredTracks=computed (()=>tracks.value.filter (t=>t.title && t.file && t.text_authors && t.music_authors && t.text));
watch (filteredTracks,filtered=>disabledNext.value= !filtered.length,{immediate:true});
const vStyle:Directive=inject ('vStyle');
const style:ComputedRef=inject ('style');
const translate:Ref=inject ('translate');
</script>
<template>
	<template v-for="(track,index) in tracks">
		<div style="display:flex;flex-direction:row;justify-content:flex-end;">
			<ui-button v-if="tracks.length>1" @click="tracks.splice (index,1)"
					   style="background-color:var(--cl-transparent);color:var(--cl-red-C);display:flex;flex-direction:row;justify-content:flex-end;">
				<ph-trash size="20px" style="width:20px;height:20px;"/>
			</ui-button>
		</div>
		<track-form v-model="tracks[index]"/>
	</template>
	<button v-style="style" @click="tracks.push ({})" class="ui-dropfile-target">
		<ph-plus size="20px"/>
		<span v-text="translate.addMoreTrack" style="margin-left:var(--sz-05)"/>
	</button>
	<track-form v-if="!tracks.length" v-model="tracks[0]"/>
</template>
<style src="./Tracks.less" lang="less"/>

export default (
	{
		profileSettings:'Profile settings',
		logout:'Logout',
		welcome:'Welcome',
		settings:'Settings',
		uploadNewRelease:'Upload new release',
		back:'Back',
		sendRelease:'Send release',
		next:'Next',
		manageUsers:'Manage users',
		themeSettings:'Theme settings',
		chooseBetweenLightAndDarkTheme:'Choose between light and dark theme',
		switchToLightTheme:'Switch to light theme',
		switchToDarkTheme:'Switch to dark theme',
		releaseTitle:'Release title',
		type:'Type',
		versionOptional:'Version (optional)',
		artistName:'Artist name',
		releaseDate:'Release date',
		preorderOptional:'Pre-order date (optional)',
		cover:'Cover',
		clickOrDragTheImage:'Click or drag the image',
		PNG_JPGupTo10MB:'PNG, JPG up to 10MB',
		pleaseCheckAllDetailsCarefullyBeforeSending:'Please check all details carefully before sending.',
		afterTheReleaseIsSubmittedForModerationDataChangesWillRequireReApproval:'After the release is submitted for moderation, data changes will require re-approval.',
		baseInformation:'Base information',
		title:'Title',
		artist:'Artist',
		releaseType:'Release type',
		version:'Version',
		preorderDate:'Pre-order date',
		tracks:'Tracks',
		textAuthors:'Text authors',
		musicAuthors:'Music authors',
		distribution:'Distribution',
		selectedPlatforms:'Selected platforms',
		iTunesPricingPolicy:'iTunes Pricing Policy',
		platformSelection:'Platform selection',
		priceCategory:'Price category',
		addMoreTrack:'Add more track',
		trackTitle:'Track title',
		audiofile:'Audiofile',
		clickToReplaceFile:'Click to replace file',
		clickOrDragTheAudioFile:'Click or drag the audio file',
		MP3_WAVupTo50MB:'MP3, WAV up to 50MB',
		lyrics:'Lyrics',
		timedTextTtmlOptional:'Timed Text (TTML) (optional)',
		selectTTMLfile:'select TTML file',
		profanity:'Profanity',
		videoShotLinkOptional:'Video shot link (optional)',
		musicClipLinkOptional:'Music clip link (optional)',
		check:'Check',
		track:'Track',
		uploadRelease:'Upload release',
		statistics:'Statistics',
		myReleases:'My releases',
		karaokeLyrics:'Karaoke lyrics',
		users:'Users',
		allReleases:'All releases',
		underReview:'Under review',
		allPlatforms:'All platforms',
		selectCategory:'Select category...',
		single:'Single',
		EP:'EP',
		album:'Album',
		maxiSingle:'Maxi single',
		languageSettings:'Language settings',
		chooseYourPreferredLanguage:'Choose your preferred language',
		emailSettings:'Email settings',
		newEmailAddress:'New Email Address',
		update:'Update',
		passwordSettings:'Password settings',
		currentPassword:'Current password',
		newPassword:'New password',
		confirmPassword:'Confirm password',
		notifications:'Notifications',
		markAllAsRead:'Mark all as read',
		statisticsWillBeAvailableSoon:'Statistics will be available soon',
		karaokeEditor:'Karaoke editor',
		subtitle:'Subtitle',
		downloadTTML:'Download TTML',
	});

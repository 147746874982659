<script setup lang="ts">
import {inject,Ref}   from "vue";
import {UiButton}     from "../../uikit";
import {PhSun,PhMoon} from "@phosphor-icons/vue";

const dark:Ref=inject ('dark');
const translate:Ref=inject ('translate');
</script>
<template>
	<div v-text="translate.themeSettings"/>
	<div v-text="translate.chooseBetweenLightAndDarkTheme"/>
	<ui-button @click="dark=!dark" class="outlined">
		<component :is="dark?PhSun:PhMoon" size="20px"/>
		<span v-if="dark" v-text="translate.switchToLightTheme"/>
		<span v-else v-text="translate.switchToDarkTheme"/>
	</ui-button>
</template>
<style scoped lang="less">
.ui-button-group
{
	width:100%;
	color:var(--cl-fg);
	background-color:var(--cl-bg);
	outline:var(--outline);
	font-size:var(--fs-3);
	
	span
	{
		margin-left:var(--sz-05);
	}
}
</style>

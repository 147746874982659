export default (
	{
		'':'selectCategory',
		P099:'$0.99',
		P129:'$1.29',
		P199:'$1.99',
		P299:'$2.99',
		P399:'$3.99',
		P499:'$4.99',
		P599:'$5.99',
		P699:'$6.99',
		P799:'$7.99',
		P899:'$8.99',
		P999:'$9.99',
	});

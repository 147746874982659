<script setup lang="ts">
import {watch,Ref,inject,computed}                    from "vue";
import {PhUploadSimple,PhTrash}                       from "@phosphor-icons/vue";
import {mapValues}                                    from "lodash";
import {UiInput,UiDropfile,UiImage,UiButton,UiSelect} from "../../uikit";
import types                                          from "./types";

const model:Ref=defineModel ();
const disabledNext=defineModel ('disabledNext');
const translate:Ref=inject ('translate');
const translatedTypes=computed (()=>mapValues (types,v=>translate.value[v]));
model.value['type']='SINGLE';
watch (model.value,value=>
{
	for (let field of ['title','type','artist','release','cover'])
		{
			if (!value[field])
				{
					disabledNext.value=true;
					return;
				}
		}
	disabledNext.value=false;
},{immediate:true});

function file (files):void
	{
		const file:File=files[0];
		if (file.size>=10485760) return;
		if (!['image/jpeg','image/png'].includes (file.type)) return;
		model.value['cover']=file;
	}
</script>
<template>
	<div>
		<ui-input v-model="model['title']">
			{{translate.releaseTitle}}
		</ui-input>
	</div>
	<div>
		<ui-select v-model="model['type']" :options="translatedTypes">
			{{translate.type}}
		</ui-select>
		<ui-input v-model="model['version']">
			{{translate.versionOptional}}
		</ui-input>
	</div>
	<div>
		<ui-input v-model="model['artist']">
			{{translate.artistName}}
		</ui-input>
	</div>
	<div>
		<ui-input type="date" v-model="model['release']">
			{{translate.releaseDate}}
		</ui-input>
		<ui-input type="date" v-model="model['preorder']">
			{{translate.preorderOptional}}
		</ui-input>
	</div>
	<div>
		<ui-dropfile @file="file" accept="image/png,image/jpeg">
			{{translate.cover}}
			<template v-if="model['cover']" #preview>
				<ui-image :src="model['cover']"/>
				<ui-button @click="model['cover']=undefined">
					<ph-trash size="20px"/>
				</ui-button>
			</template>
			<template v-else #dropzone>
				<ph-upload-simple size="48px"/>
				<div v-text="translate.clickOrDragTheImage"/>
				<div v-text="translate.PNG_JPGupTo10MB"/>
			</template>
		</ui-dropfile>
	</div>
</template>
<style src="./Base.less" scoped lang="less"/>

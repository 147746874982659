import {createApp} from "vue";
import router from "./router";
import {axiosWrapper as axios} from "bomber-net-axios-wrapper-ts";
import App from "./vue/app/App.vue";

axios.defaults.headers.common['X-Requested-With']='XMLHttpRequest';
axios.defaults.baseURL=`${window.location.origin}/xhr`;

const instance=createApp (App);
instance.use (router);
instance.provide ('axios',axios);
instance.mount ('body');

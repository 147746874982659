<script setup lang="ts">
import {Ref,inject,ref}          from "vue";
import {UiInput,UiForm,UiButton} from "../../uikit";

const translate:Ref=inject ('translate');
const user:Ref=inject ('user');
const error=ref (undefined);

function errors (errors)
	{
		error.value=errors?.email[0];
	}
</script>
<template>
	<div v-text="translate.emailSettings"/>
	<ui-form :data="{email:user.email}" :target="`user/${user.id}/email`" @errors="errors" method="put">
		<ui-input v-model="user.email" v-model:error="error" type="email">
			{{translate.newEmailAddress}}
		</ui-input>
		<ui-button type="submit">
			{{translate.update}}
		</ui-button>
	</ui-form>
</template>
<style scoped lang="less">
</style>

<script setup lang="ts">
import {ComputedRef,inject,Directive,computed,Ref} from "vue";

const props=defineProps (
	{
		disabled:Boolean,
		readonly:Boolean,
	});
const model:Ref=defineModel ();
const error:Ref=defineModel ('error');
const vStyle:Directive=inject ('vStyle');
const style:ComputedRef=inject ('style');
const bind=computed (()=>
	{
		const {disabled,readonly}=props;
		return {disabled,readonly};
	});
</script>
<template>
	<label v-style="style" class="ui-element-group ui-checkbox-group">
		<div class="ui-element-main ui-checkbox-main">
			<input v-model="model" v-bind="bind" type="checkbox" @focus="$emit ('update:error',undefined)" class="ui-checkbox">
			<span v-if="$slots.default" class="ui-label ui-checkbox-label">
				<slot/>
			</span>
		</div>
		<div v-if="error" v-text="error" class="ui-error ui-checkbox-error"/>
	</label>
</template>
<style src="./UiCheckbox.less" scoped lang="less"/>

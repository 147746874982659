<script setup lang="ts">
import {ref,inject,watch,isProxy,Ref} from "vue";
import {useRouter}                    from "vue-router";
import {forOwn,isPlainObject,pickBy}  from "lodash";
import {PhCheck}                      from "@phosphor-icons/vue";
import {AxiosWrapper}                 from "bomber-net-axios-wrapper-ts";
import {UiButton}                     from "../uikit";
import baseStage                      from "./upload-release/Base.vue";
import tracksStage                    from './upload-release/Tracks.vue';
import distributionStage              from './upload-release/Distribution.vue';
import checkStage                     from './upload-release/Check.vue';
import platforms                      from "./upload-release/platforms";

const router=useRouter ();
const axios:AxiosWrapper=inject ('axios');
const translate:Ref=inject ('translate');
const stages=
	[
		{component:baseStage,title:translate.value.baseInformation},
		{component:tracksStage,title:translate.value.tracks},
		{component:distributionStage,title:translate.value.distribution},
		{component:checkStage,title:translate.value.check},
		{component:null,title:null},
	];
const stage=ref (0);
const release=ref ({});
const disabledNext=ref (true);
watch (stage,stage=>
{
	if (stage>=4)
		{
			if (release.value['platformSelect']==='all') release.value['platforms']=Object.keys (platforms);
			delete release.value['platformSelect'];
			const d=dotted (release.value);
			const data=Object.keys (pickBy (d,(i:any)=>i instanceof File)).length?new FormData ():release.value;
			if (data instanceof FormData)
				{
					forOwn (d,(v:any,k:string)=>
					{
						const path=k.split ('.');
						let key=path.shift ();
						while (path.length) key+=`[${path.shift ()}]`;
						if (v instanceof File) data.append (key,v,v.name);
						else data.append (key,v)
					});
				}
			axios.post ('release',data).success (()=>router.push ('/')).run ();
		}
});

function dotted (data:object|Array<any>,name?:string):object
	{
		const d={};
		forOwn (data,(v:any,k)=>
		{
			if (v instanceof File || !(isProxy (v) || isPlainObject (v))) d[name?`${name}.${k}`:k]=v;
			else Object.assign (d,dotted (v,name?`${name}.${k}`:k));
		});
		return d;
	}
</script>
<template>
	<div v-text="translate.uploadNewRelease" class="h1"/>
	<div id="stage">
		<template v-for="(stageObject,index) in stages">
			<div v-if="stageObject.title" :class="stage<index?'step-wait':(stage>index?'step-pass':'step-current')">
				<div>
					<ph-check v-if="stage>index" weight="bold"/>
					<span v-else>{{index+1}}</span>
				</div>
				<div v-text="stageObject['title']"/>
			</div>
		</template>
	</div>
	<component :is="stages[stage].component" v-model="release" v-model:disabledNext="disabledNext"/>
	<div id="expander"/>
	<div>
		<div>
			<ui-button v-if="stage" @click="stage--" id="button-back" v-text="translate.back"/>
		</div>
		<div>
			<ui-button :disabled="disabledNext" v-text="stage>2?translate.sendRelease:translate.next" @click="stage++"/>
		</div>
	</div>
</template>
<style src="./UploadRelease.less" lang="less"/>

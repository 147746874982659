<script setup lang="ts">
import {Ref,inject,ref}          from "vue";
import {mapValues}               from "lodash";
import {UiInput,UiForm,UiButton} from "../../uikit";

const translate:Ref=inject ('translate');
const user:Ref=inject ('user');
const data=ref ({});
const error=ref ({});

function errors (errors)
	{
		error.value=mapValues (errors,e=>e[0]);
	}
</script>
<template>
	<div v-text="translate.passwordSettings"/>
	<ui-form :data="data" :target="`user/${user.id}/password`" @errors="errors" method="put">
		<ui-input v-model="data['current']" v-model:error="error['current']" type="password">
			{{translate.currentPassword}}
		</ui-input>
		<ui-input v-model="data['new']" v-model:error="error['new']" type="password">
			{{translate.newPassword}}
		</ui-input>
		<ui-input v-model="data['confirm']" v-model:error="error['confirm']" type="password">
			{{translate.confirmPassword}}
		</ui-input>
		<ui-button type="submit">
			{{translate.update}}
		</ui-button>
	</ui-form>
</template>
<style scoped lang="less">
</style>

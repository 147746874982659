<script setup lang="ts">
import {computed,Directive,inject,ComputedRef} from "vue";
import {format}                                from "@formkit/tempo";
import {UiImage}                               from "../../uikit";
import {PhCalendarBlank}                       from "@phosphor-icons/vue";

const props=defineProps (
	{
		release:{type:Object,required:true},
	});
const releaseDate=computed (()=>format (new Date (props.release.release),'D.MM.YYYY'));
const vStyle:Directive=inject ('vStyle');
const style:ComputedRef=inject ('style');
</script>
<template>
	<div v-style="style" class="release-box">
		<ui-image :src="release.cover"/>
		<div>
			<div v-text="release.title"/>
			<div v-text="release.artist"/>
			<div>
				<ph-calendar-blank size="16px"/>
				<span v-text="releaseDate"/>
			</div>
		</div>
	</div>
</template>
<style src="./ReleaseBox.less" scoped lang="less"/>

<script setup lang="ts">
import {inject,ComputedRef,ModelRef,Directive,computed} from "vue";

const props=defineProps (
	{
		options:{type:Object,required:true},
		disabled:Boolean,
		readonly:Boolean,
	});
const model:ModelRef<string>=defineModel ();
const error:ModelRef<string>=defineModel ('error');
const bind=computed (()=>
	{
		const {disabled,readonly}=props;
		return {disabled,readonly};
	});
const style:ComputedRef=inject ('style');
const vStyle:Directive=inject ('vStyle');
</script>
<template>
	<label v-style="style" class="ui-element-group ui-select-group">
		<div class="ui-element-main ui-select-main">
			<span v-if="$slots.default" class="ui-label ui-select-label">
				<slot/>
			</span>
			<select v-model="model" v-bind="bind" @focus="$emit ('update:error',undefined)" class="ui-select">
				<option v-for="(option,value) in options" :value="value" v-text="option"/>
			</select>
		</div>
		<div v-if="error" v-text="error" class="ui-error ui-select-error"/>
	</label>
</template>
<style src="./UiSelect.less" scoped lang="less"/>

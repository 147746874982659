<script setup lang="ts">
import {reactive,watch,ref,Ref,inject,computed} from "vue";
import {pickBy,toPairs,chunk,mapValues}         from "lodash";
import {UiRadio,UiCheckbox,UiSelect}            from "../../uikit";
import platforms                                from "./platforms";
import platformOptions                          from "./platformOptions";
import priceOptions                             from "./priceOptions";

const model:Ref=defineModel ();
const disabledNext:Ref=defineModel ('disabledNext');
const translate:Ref=inject ('translate');
if (!model.value['platformSelect']) model.value['platformSelect']='select';

const translatedPlatformOptions=computed (()=>mapValues (platformOptions,v=>translate.value[v]));

const platformPairs=chunk (toPairs (platforms),2);

const checkedPlatforms=reactive ({});
if (model.value['platforms']) model.value['platforms'].forEach (platform=>checkedPlatforms[platform]=true);
watch (checkedPlatforms,platform=>model.value['platforms']=Object.keys (pickBy (platform,v=>v)),{immediate:true});

const translatedPriceOptions=computed (()=>
{
	const options=priceOptions;
	options['']=translate.value[options['']];
	return options;
});

const priceType=ref ('');
if (model.value['price_type']) priceType.value=model.value['price_type'];
watch (priceType,p=>model.value['price_type']=p?p:undefined,{immediate:true});

watch (model.value,v=>disabledNext.value= !(v['price_type'] && (v['platformSelect']==='all' || v['platforms'].length)),{immediate:true});
</script>
<template>
	<div>
		<div v-text="translate.platformSelection" style="font-size:var(--fs-3);font-weight:var(--fw-7);"/>
		<div></div>
	</div>
	<div>
		<ui-radio name="platformSelect" :options="translatedPlatformOptions" v-model="model['platformSelect']"/>
		<div></div>
	</div>
	<div v-show="model['platformSelect']==='select'" id="distribution-platforms">
		<div v-for="pair in platformPairs">
			<ui-checkbox v-model="checkedPlatforms[pair[0][0]]">
				{{pair[0][1]}}
			</ui-checkbox>
			<ui-checkbox v-model="checkedPlatforms[pair[1][0]]">
				{{pair[1][1]}}
			</ui-checkbox>
		</div>
	</div>
	<div v-text="translate.iTunesPricingPolicy" style="font-size:var(--fs-3);font-weight:var(--fw-7);"/>
	<div style="margin-top:var(--sz-05)">
		<ui-select v-model="priceType" :options="translatedPriceOptions">
			{{translate.priceCategory}}
		</ui-select>
	</div>
</template>
<style src="./Distribution.less" scoped lang="less"/>

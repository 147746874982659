import releases from "./admin/releases";
import pending from "./admin/pending";
import users from "./admin/users";
import settings from "./admin/settings";

export default (
	{
		releases,
		pending,
		users,
		settings,
	});

<script setup lang="ts">
import {ref,watch} from "vue";
import {isString}  from "lodash";

const props=defineProps (
	{
		src:{required:true}
	});
const imgSrc=ref (undefined);
watch (()=>props.src,async src=>
{
	switch (true)
		{
			case src instanceof File:
				if (src.type.split ('/')[0]!=='image') return;
				const reader=new FileReader ();
				reader.readAsDataURL (src);
				reader.onload=()=>imgSrc.value=reader.result;
				break;
			case isString (src):
				imgSrc.value=src;
				break;
		}
},{immediate:true});
</script>
<template>
	<img v-if="imgSrc" :src="imgSrc" alt="" class="ui-image">
</template>
<style scoped lang="less">
.ui-image
{
	border-radius:var(--sz-05);
}
</style>

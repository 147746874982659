<script setup lang="ts">
import {computed,Directive,inject,ComputedRef} from "vue";
import {format}                                from "@formkit/tempo";
import {PhClock}                               from "@phosphor-icons/vue";

const props=defineProps (
	{
		notification:{type:Object,required:true},
	});
defineEmits (['select']);
const created=computed (()=>
{
	const created=new Date (props.notification.created_at);
	return format (created,'D.MM.YYYY H:mm:ss');
});
const icon=computed (()=>
{
	switch (props.notification.class)
		{
			case 'release':
				return PhClock;
		}
});
const vStyle:Directive=inject ('vStyle');
const style:ComputedRef=inject ('style');
</script>
<template>
	<div v-style="style" @click="$emit ('select',notification.uuid)" :class="`notification-${notification.class}`" class="notification-groupp">
		<component :is="icon" size="20px" weight="bold" :class="`notification-icon-${notification.class}`" class="notification-icon"/>
		<div class="notification-message-group">
			<div v-text="notification.message"/>
			<div v-text="created"/>
		</div>
	</div>
</template>
<style src="./NotificationItem.less" scoped lang="less"/>
